<template>
    <div class="reportEatserveDetail defaultReport">
        <div class="content-box clearfix">
            <div class="filter-box">
                <div class="filter-bnts ">
                    <div class="inline-block" >
                        <div class="flex-box">
                            <div class="lable-txt">营业日期：{{data?.Rpt_Date}} </div>
                        </div>
                    </div>
                   
                    <div class="inline-block" >
                        <div class="flex-box">
                            <div class="lable-txt">销售渠道:</div>
                            <div class="from-input" style="width: 180px;">
                                <select-tree v-model="channelAutoIDs" placeholder="请选择" :data="treeOrderChannel" title="销售渠道选择">
                                </select-tree>
                            </div>
                        </div>
                    </div>
                    <div class="inline-block" >
                        <div class="flex-box">
                            <div class="search-flex">
                                <div class="bnt-search" @click="GetBillCheck()" >查询</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-bnts">
                    <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
                </div>
                
            </div>
            <div class="table-box grey-table headerCol2" ref="tableBox">
                <el-table class="el-table--scrollable-y"  :data="tableListPage" ref="elTable"
                 border 
                @cell-dblclick="orderDetail"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column fixed prop="Rpt_Date" label="营业日期" min-width="90">
                        <template #default>
                            {{data?.Rpt_Date}}
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="Eat_CheckIndex" align="left" label="结账单号" min-width="160"></el-table-column>
                    <el-table-column fixed prop="Eat_DeskName" align="left" label="桌号" min-width="100"></el-table-column>
                    <el-table-column prop="Eat_ManNum" label="人数" min-width="50" data-format="number"></el-table-column>
                    <el-table-column prop="Eat_XFCode" label="菜品编码" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_XFName" label="菜品名称" min-width="80"></el-table-column>
                    <el-table-column prop="Eat_Number" label="下单数量" min-width="60" align="right" data-format="number"></el-table-column>
                    <el-table-column prop="Refund_Number" label="退菜数量" min-width="60" align="right" data-format="number">
                        <template #default="scope">
                             <span class="link" v-if="scope.row.Refund_Number!=0">{{scope.row.Refund_Number}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_XFSize" label="单位" min-width="50"></el-table-column>
                    <el-table-column prop="ISKDSNOSHOW" label="是否考核" min-width="75">
                        <template #header>
                            <el-tooltip  effect="dark" content="来源菜品设置项【KDS不显示】作为不考菜品" placement="top">
                                <div class="tip">是否考核
                                    <svg class="icon-svg" aria-hidden="true">
                                        <use xlink:href="#icon-infoFilled"></use>
                                    </svg>
                                </div>
                            </el-tooltip>
                        </template>
                        <template #default="scope">
                            <template v-if="scope.isExcel">
                                {{scope.row.ISKDSNOSHOW?"否":"是"}}
                            </template>
                            <span class="kdsNoShow" v-else>
                                <i class="iconfont icon-cha" v-if="scope.row.ISKDSNOSHOW"></i>
                                <i class="iconfont icon-gou" v-else></i>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="上菜顺序">
                        <el-table-column prop="ServingOrder_InBook" label="理论" min-width="80" data-format="number"></el-table-column>
                        <el-table-column prop="ServingOrder_InReal" label="实际" min-width="80" data-format="number"></el-table-column>
                    </el-table-column>
                    <el-table-column prop="Food_level" label="菜品等级" min-width="60" data-format="number"></el-table-column>
                    <el-table-column label="系统标准值(单位:分)">
                        <el-table-column prop="KIT_LENGTH" label="标准时长" min-width="80" data-format="number">
                            <template #header>
                                <el-tooltip  effect="dark" content="来源菜品设置项【制作标准时长】" placement="top">
                                    <div class="tip">标准时长
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="EndCookTime" label="理论完成时间" min-width="100">
                            <template #header>
                                <el-tooltip  effect="dark" content="按制作标准时长和菜品等级运算值" placement="top">
                                    <div class="tip">理论完成时间
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="出菜(单位:分)">
                        <el-table-column prop="RealCookTime" label="出菜时长" min-width="80">
                            <template #header>
                                <el-tooltip  effect="dark" content="划菜时间-起菜时间" placement="top">
                                    <div class="tip">出菜时长
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="OverTime" label="超时时长" min-width="80">
                            <template #header>
                                <el-tooltip  effect="dark" content="划菜时间-理论完成时间" placement="top">
                                    <div class="tip">超时时长
                                        <svg class="icon-svg" aria-hidden="true">
                                            <use xlink:href="#icon-infoFilled"></use>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="TransferFoodTime" label="传菜时长(单位:分)" min-width="80">
                        <template #header>
                            <el-tooltip  effect="dark" content="上桌时间-划菜时间" placement="top">
                                <div class="tip">传菜时长
                                    <svg class="icon-svg" aria-hidden="true">
                                        <use xlink:href="#icon-infoFilled"></use>
                                    </svg>
                                    (单位:分)
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="前厅操作">
                        <el-table-column prop="Eat_Time" label="点菜" min-width="70" >
                            <template #default="scope">
                                {{scope.row.Eat_Time}}
                                <p class="small">{{scope.row.Eat_Czy}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="CallUp_Time" label="起菜" min-width="70" >
                            <template #default="scope">
                                {{scope.row.CallUp_Time}}
                                <p class="small">{{scope.row.CallUp_Czy}}</p>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="后厨操作">
                        <el-table-column prop="Cut_Time" label="切配" min-width="70" >
                            <template #default="scope">
                                {{scope.row.Cut_Time}}
                                <p class="small">{{scope.row.Cut_Czy}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Do_Time" label="制作" min-width="70" >
                            <template #default="scope">
                                {{scope.row.Do_Time}}
                                <p class="small">{{scope.row.Do_Czy}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="End_Time" label="划菜" min-width="70" >
                            <template #default="scope">
                                {{scope.row.End_Time}}
                                <p class="small">{{scope.row.End_Czy}}</p>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="OnDesk_Time" label="上桌" min-width="70" >
                        <template #default="scope">
                            {{scope.row.OnDesk_Time}}
                            <p class="small">{{scope.row.OnDesk_Czy}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Food_Status" label="菜品状态" min-width="70" >
                        <template #default="scope">
                            {{["待切配","已切配 待制作","已制作 待划菜","已划菜 待上桌","已上桌"][scope.row.Food_Status]}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_MasName" label="要求" min-width="160" ></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
        
     </div>
</template>
<script>


/**上菜顺序速度明细表 */
export default {
    name:'backFoodTimeReport',
    props:{
        data:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            isExcel:false,
            userInfo:{},
            /**表格数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
            //渠道数据
            orderChannel:null,
            //渠道id
            channelAutoIDs:[],
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        //渠道数据
        this.$cacheData.OrderChannel().then((d)=>{
            if(d){
                this.orderChannel=d;
            }
        }).catch((e)=>{
            this.$message.error('加载渠道数据失败：'+e.message);
            console.log('加载渠道数据失败：'+e);
        })

    },
    computed:{
        //渠道数据
        treeOrderChannel(){
            let treeData=[];
            this.orderChannel?.map((group)=>{
                let json={
                    id:group.OrderChannelKind_AutoID,
                    name:group.OrderChannelKind_Code+group.OrderChannelKind_Name,
                    children:[],
                    Hierarchy:1
                }
                if(group.OrderChannelList){
                    group.OrderChannelList.map(item=>{
                        let children= Object.assign({
                            id:item.OrderChannel_AutoID,
                            name:item.OrderChannel_Code+'-'+item.OrderChannel_Name,
                            Hierarchy:2
                        },item)
                        json.children.push(children)
                    })
                }
                treeData.push(json)
            })
            return treeData;
        },
    },
    watch:{
        data:{
            handler(){
                if(this.data){
                   this.$nextTick(()=>{
                        this.GetBillCheck();
                   })
                }
            },
            immediate:true,
        }
    },
    methods:{
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 80;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            if(this.$refs.elTable){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.elTable,
                    titleName:"上菜顺序速度明细表",
                    list:this.tableList,
                    headArr:[
                        [
                            {value:'营业日期：'+this.data?.Rpt_Date,col:3}, 
                        ],
                    ]
                })
            }
            
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**查询 */
        GetBillCheck(){
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Rpt_Date:this.data?.Rpt_Date,//日期
                Eat_AutoID:this.data?.Eat_AutoID,//订单id
                CHANNEL_ID:this.channelAutoIDs,//渠道id
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log(param)
            this.$httpAES.post("Bestech.CloudPos.FoodServingOrderDetailReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[];
                this.currentPage=1
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        //穿透
        orderDetail(row,column){
            if(column.property=="Refund_Number" && row.Refund_Number!=0){//穿透 退菜时间分析表
                this.$emit("addFrame",{
                    key:"backFood"+row.Eat_AutoID+row.Eat_XFBMID,
                    data:{
                        Rpt_Date:this.data?.Rpt_Date,
                        Eat_CheckIndex:row.Eat_CheckIndex,
                        Eat_DeskName:row.Eat_DeskName,
                        Eat_AutoID:row.Eat_AutoID,
                        Eat_XFBMID:row.Eat_XFBMID,
                        Eat_XFName:row.Eat_XFName
                    },
                    title:'退菜时间分析表',
                    url:'backFoodTimeReport'
                });
            }
        },
    }
}
</script>

<style lang="scss">
@import './reportEatserveDetail.scss'
</style>